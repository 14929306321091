import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Button, Carousel, Col, Row } from "antd";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ROUTE_PATHS } from "../../../../constants/url-config";
import useResponsive from "../../../../hooks/useResponsive";
import { useSelector } from "../../../../redux/store";
import "./OurService.scss";

type Props = {};

const OurService = (props: Props) => {
  const { isMobile } = useResponsive();
  console.log("🚀 ~ file: OurService.tsx:16 ~ OurService ~ isMobile:", isMobile)
  const carouselRef = useRef<any>(null);
  const { t } = useTranslation();

  const {services} = useSelector((state)=> state.event)

  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  // Hàm để chuyển slide trước đó
  const handlePrev = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
    }
  };

  const isVideo = (url: string) => {
    return /\.(mp4|webm|ogg|mov)$/i.test(url);
  };

  return (
    <div >
     <Row gutter={[0, { xs: 24, xl: 40 }]}  className="ourService containerCustom">
        <Col xs={24}>
          <Row justify="space-between">
            <Col xs={12}>
              <h2 className="title ourService--title">{t('ourService')}</h2>
            </Col>
            <Col xs={12} >
              <div className="buttonOurService">
                <Button
                  className="buttonOurService__prev"
                  type="primary"
                  shape="circle"
                  onClick={() => {
                    handlePrev()
                  }}
                  icon={<ArrowLeftOutlined/>}
                />
                <Button
                  className="buttonOurService__next"
                  type="primary"
                  shape="circle"
                  onClick={() => {
                    handleNext()
                  }}
                  icon={<ArrowRightOutlined />}
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={24} data-aos="fade-up"
             data-aos-duration="700">
          <Carousel
             dots={false}
             autoplay
             autoplaySpeed={3000}
            slidesToShow={isMobile ? 1 : 4}
            ref={carouselRef}
            lazyLoad="ondemand"
            className="carouselCustom"
          >
        {services.map(eventService => 
            eventService.id === 124 ? (
              <a 
                key={eventService.id} 
                href="https://livestreamhub.agari.com.vn/" 
                target="_self"
                className="cardItem-link"
              >
                <div className="cardItem">
                  {isVideo(eventService.thumbnail) ? (
                    <video
                      className="article-card-img"
                      src={eventService.thumbnail}
                      autoPlay
                      loop
                      muted 
                      style={{ border: 'none', objectFit: 'fill' }} 
                    />
                  ) : (
                    <img src={eventService.thumbnail} alt="Card" />
                  )}
                  <div className="cardItem__content">{eventService.name}</div>
                </div>
              </a>
            ) : (
              <Link 
                key={eventService.id}
                to={ROUTE_PATHS.ServiceDetail.replace(":id", eventService.id.toString())} 
                className="cardItem-link"
              >
                <div className="cardItem">
                  {isVideo(eventService.thumbnail) ? (
                    <video
                      className="article-card-img"
                      src={eventService.thumbnail}
                      autoPlay
                      loop
                      muted 
                      style={{ border: 'none', objectFit: 'fill' }} 
                    />
                  ) : (
                    <img src={eventService.thumbnail} alt="Card" />
                  )}
                  <div className="cardItem__content">{eventService.name}</div>
                </div>
              </Link>
            )
          )}
          </Carousel>
        </Col>
      </Row>

    </div>
  );
};

export default OurService;
